import {
  SET_LOADING,
  GET_MAP_DATA,
  GET_REPORT_DATA,
  SET_LOADING_MAP,
  GET_LOGS_DATA,
  GET_NEW_LOGS_DATA
} from "./types";
import api from '../api/dashboardApi'

export const getMapData = ({rightHolderId}) => dispatch => {
  api
    .getMapData({ from: null, to: null, type: 'both', status: 'both',rightHolderId })
    .then(response => {
      dispatch({ type: SET_LOADING_MAP, payload: false });
      dispatch({ type: GET_MAP_DATA, payload: response.data });
      dispatch({ type: SET_LOADING_MAP, payload: false });
    })
    .catch(() => {
      dispatch({ type: SET_LOADING_MAP, payload: false });
    });
};

export const setLoadingMap = (value) => dispatch => {
  dispatch({ type: SET_LOADING_MAP, payload: value });
};

export const getReportData = ({ data, pagination, filters, join, sort, customParams }) => dispatch => {
  api
    .getReportData(data, { pagination, filters, join, sort, customParams })
    .then(response => {
      dispatch({ type: GET_REPORT_DATA, payload: response.aggregatedData[0] });
      dispatch({ type: SET_LOADING, payload: false });
    })
    .catch(() => {
      dispatch({ type: SET_LOADING, payload: false });
    });
};

export const setIsLoading = (value) => dispatch => {
  dispatch({ type: SET_LOADING, payload: value });
};

export const getLogsData = ({customParams}) => dispatch => {
  api
    .getLogs({customParams})
    .then(response => {
      dispatch({ type: GET_LOGS_DATA, payload: response.data });
      dispatch({ type: SET_LOADING, payload: false });
    })
    .catch(() => {
      dispatch({ type: SET_LOADING, payload: false });
    });
};


export const getNewLogsData = ({customParams}) => dispatch => {
  api
    .getLogs({customParams})
    .then(response => {
      dispatch({ type: GET_NEW_LOGS_DATA, payload: response.data });
      dispatch({ type: SET_LOADING, payload: false });
    })
    .catch(() => {
      dispatch({ type: SET_LOADING, payload: false });
    });
};