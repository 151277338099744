import React, { useRef, useState } from "react";
import Geocode from 'react-geocode';
import { connect } from 'react-redux'
import GoogleMapReact from 'google-map-react';
import useSupercluster from "use-supercluster";
const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY || "AIzaSyD0B9-HIsfQYsBdYu8c4ykw0vuo0etUw00";
Geocode.setApiKey(GOOGLE_API_KEY);

const HeatMap = (props) => {

  const { mapData } = props;
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(2);
  const points = mapData.map((item, idx) => ({
    type: "Feature",
    properties: { cluster: false, id: idx },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(item.long),
        parseFloat(item.lat)
      ]
    }
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 100, maxZoom: 20 }
  });

  const Marker = ({ children }) => children;

  const defaultCenter = {
    lat: 23.8859,
    lng: 45.0792,
  };

  return (
    <div className="d-flex d-flex flex-row flex-grow-1 flex-xl-grow-0 mapCtr mb-3 mb-xl-0">
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        defaultCenter={defaultCenter}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        }}
        options={
          { mapTypeId: 'satellite', disableDefaultUI: true }
        }
      >
        {clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            point_count: pointCount
          } = cluster.properties;
          
          return (
            <Marker
              key={`cluster-${cluster.id}`}
              lat={latitude}
              lng={longitude}
            >
              <button
                className="cluster-marker"
                onClick={() => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    20
                  );
                  mapRef.current.setZoom(expansionZoom);
                  mapRef.current.panTo({ lat: latitude, lng: longitude });
                }}
              >
                <img src={require('../assets/images/clusterer.svg')} className="p-absolute" alt="cluster"/>
                <p>{pointCount || 1}</p>
              </button>
            </Marker>
          );

        })}
      </GoogleMapReact>
    </div>
  );

}

const mapStateToProps = ({ dashboardReducer: { mapData } }) => ({ mapData })
export default connect(mapStateToProps, {})(HeatMap)