import React from "react";
import Lottie from 'lottie-react-web';
import animation from '../assets/lottie/africaMap.json';
import asia from '../assets/lottie/asiaMap.json';
import america from '../assets/lottie/samericaMap.json';


const AnimatedMap = () => (
  <div className="darkBg h-60 d-flex flex-column py-3">
    <p className="mb-2 px-3 font400 px14 title">Top 3 Counterfeit Continent</p>
    <div className="d-flex flex-row justify-content-between px-3 w-90 h-75">
      <div className="d-flex flex-column">
        <p className="mb-2 font400 px14 white  text-center">AFRICA 62%</p>
        <Lottie
          options={{
            animationData: animation,
          }}
          width={110}
          height={"100%"}
        />
      </div>
      <div>
        <p className="mb-2 font400 px14 white text-center">ASIA 22%</p>
        <Lottie
          options={{
            animationData: asia,
          }}
          width={115}
          height={"90%"}
        />
      </div>
      <div>
        <p className="mb-2 font400 px14 white text-center">SOUTH AMERICA 16%</p>
        <Lottie
          options={{
            animationData: america,
          }}
          width={72}
          height={"100%"}
        />
      </div>
    </div>
  </div>
)

export default AnimatedMap;