import React from "react";
import FlipNumbers from "react-flip-numbers";

const AggregatedItem = ({ value, title, icon, customClass }) => (
    <div className={`total d-flex flex-row align-items-center ${customClass}`}>
      {icon()}
      <div className="d-flex flex-column">
        <p className="title px14 mb-0 font400">{title}</p>
        <FlipNumbers
          play
          color="#fff"
          width={14}
          height={20}
          numbers={`${value}`}
          duration={1}
        />
      </div>
    </div>
  )

  export default AggregatedItem;