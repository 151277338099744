import React, { useEffect } from "react"
import Header from '../components/header'
import Loader from '../components/loader'
import SEO from "../components/seo"
import { connect } from 'react-redux'
import HeatMap from "../components/heatmapnew"
import { navigate } from 'gatsby-link'
import AggregatedData from "../components/aggregatedData"
import LogsTable from '../components/logsTable'
import AnimatedMap from "../components/animatedMap"
import Industries from "../components/industries"
import socket from "../socket"
import { getReportData, setIsLoading, getMapData, setLoadingMap, getLogsData, getNewLogsData } from "../actions/dashboardActions";

const Dashboard = props => {

  const { user, isLoading, loadingMap } = props

  useEffect(() => {
    if (!user) {
      typeof window !== 'undefined' && navigate('/')
    }
    props.setIsLoading(true);
    props.setLoadingMap(true);
    if(user && user.type === "right_holder"){
      props.getLogsData({customParams: {rightHolderId: user.rightHolderId}});
    } else {
      props.getLogsData({});
    }
    refresh()
    socket.on("new-log", (msg) => {
      console.log({msg})
      refresh()
    });
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const refresh = () => {
    if(user && user.type === "right_holder"){
      props.getReportData({
        data: {rightHolderId:user.rightHolderId}, pagination: {}, sort: ['created_at,DESC'],
        customParams: { scanType: 'verify' }
      });
      props.getMapData({rightHolderId:user.rightHolderId});
      props.getNewLogsData({customParams: {rightHolderId: user.rightHolderId}});
    } else {
      props.getReportData({
        data: {}, pagination: {}, sort: ['created_at,DESC'],
        customParams: { scanType: 'verify' }
      });
      props.getMapData({});
      props.getNewLogsData({});
    }
  }

  return (
    <div className="dashboardBg d-flex flex-column justify-content-center">
      {(isLoading || loadingMap) && <Loader />}
      <SEO title="Dashboard" />
      <Header />
      <div className="d-flex px-4 py-2 h90vh logInBg flex-column">
        <div className="d-flex flex-wrap flex-xl-no-wrap">
          <AggregatedData />
          <LogsTable />
        </div>
        <div className="d-flex flex-wrap flex-xl-nowrap">
          <HeatMap />
          <div className="animationCtr d-flex flex-column flex-grow-1">
            <AnimatedMap />
            <Industries />
          </div>
        </div>

      </div>
    </div>
  )
}
const mapStateToProps = ({ userReducer: { user }, dashboardReducer: { isLoading, loadingMap } }) => ({
  isLoading, user, loadingMap
})

export default connect(mapStateToProps, { setIsLoading, getReportData, getMapData, setLoadingMap, getLogsData, getNewLogsData })(Dashboard)