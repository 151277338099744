import React, { useEffect } from 'react'
import Dashboard from './dashboard'
import { connect } from 'react-redux'
import { getCurrentUser, logoutUser } from '../actions/authActions'

const App = props => {

  const { user } = props

  useEffect(() => {
    user && props.getCurrentUser(user)
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  return (
      <Dashboard />
  )
}

const mapStateToProps = ({ userReducer: { user } }) => ({ user })
export default connect(mapStateToProps, {
  getCurrentUser, logoutUser
})(App)
