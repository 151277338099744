import React from "react";
import Lottie from 'lottie-react-web';
import animation from '../assets/lottie/pharmaIndustry.json'
import fashion from '../assets/lottie/fashionIndustry.json'
import electronic from '../assets/lottie/electronicIndustry.json'

const Industries = () => {

  return (
    <div className="darkBg h-40 d-flex p-3 flex-column barChart">
      <p className="mb-2 font400 px14 title">Top 3 Counterfeit Industries</p>
      <div className="d-flex flex-row">
      <p className="mb-1 font400 px14 white w-25">Pharmaceutical</p>
        <Lottie
          options={{
            animationData: animation,
          }}
          height={20}
        />
      </div>
      <div className="d-flex flex-row">
      <p className="mb-1 font400 px14 white w-25">Fashion</p>
        <Lottie
          options={{
            animationData: fashion,
          }}
          height={20}
        />
      </div>
      <div className="d-flex flex-row">
      <p className="mb-1 font400 px14 white w-25">Electronics</p>
        <Lottie
          options={{
            animationData: electronic,
          }}
          height={20}
        />
      </div>
    </div>
  )
}

export default Industries