import React, { useState, useEffect } from 'react'
import originAllLogo from '../assets/images/darkBig-logo.svg'
import { Link } from 'gatsby'
import { connect } from "react-redux"
import moment from 'moment'
import {FiLogOut} from 'react-icons/fi'
import {Button} from '@material-ui/core'
import {logoutUser} from "../actions/authActions"

const Header = (props) => {
  const {user} = props;
  const locale = 'en';
  const [today, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => { // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    }
  }, []);

  const date = `${today.getDate()} ${today.toLocaleDateString(locale, { month: 'long' })} 2021`;
  const hour = moment(today).format("LT")

  return (
  <>
    <div className="header d-flex align-items-center justify-content-between">
        <Link to="/app" className="d-flex">
          <img src={originAllLogo} alt="logo" />
        </Link>
        <p className="mb-0 px16 font400 d-lg-inline d-none">
          We aim to create a world with no counterfeit, trying to eliminate all fake products in the market
        </p>
        <p className="mb-0 time px16 font400">{date} {hour}</p>
        <Button onClick={props.logoutUser}><FiLogOut className='logout-icon'/></Button>
    </div>
      {user && user.type === 'right_holder' &&
      <div className='d-flex align-items-center justify-content-center' >
          <p className='right-holder-name px16 font400 my-2'>
              {user.rightHolder && user.rightHolder.name}
          </p>
      </div>
      }
    </>
  )
};

const mapStateToProps = ({ userReducer: { user } }) => ({ user })
export default connect(mapStateToProps,{logoutUser})(Header)
