import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { AiOutlineScan } from 'react-icons/ai'
import { BsXOctagon } from 'react-icons/bs'
import { FiCheckCircle } from 'react-icons/fi'
import { Doughnut } from 'react-chartjs-2';
import FlipNumbers from "react-flip-numbers";
import 'chartjs-plugin-labels';
import AggregatedItem from "./aggregatedItem";

const AggregatedData = (props) => {

  const { aggregatedData } = props;
  const [data, setData] = useState({});
  const options = {
    legend: {
      display: false,
    },
    plugins: {
      labels: [
        {
          render: (args) => {
            const per = args.index === 0 ? Math.round(aggregatedData.fail_verify_count * 100 / aggregatedData.total_verify_count) : 100 - Math.round(aggregatedData.fail_verify_count * 100 / aggregatedData.total_verify_count);
            return `(${per}%)`
          },
          fontSize: 12,
          fontColor: "#FFFFFF",
          precision: 1,

        }
      ],
    },
    maintainAspectRatio: false,
  };

  useEffect(() => {
    const tmp = {
      labels: ["Failed", "Succeeded"],
      datasets: [{
        data: [aggregatedData.fail_verify_count, aggregatedData.pass_verify_count],
        backgroundColor: ["#FEBC11", "#0072BC"],
        borderColor: '#fff',
        borderWidth: 2
      }]
    };
    setData(tmp)
  }, [aggregatedData])

  return (
    <div className="d-flex flex-row flex-grow-1 flex-xl-grow-0 justify-content-between align-items-center px-5 aggregated mb-3">
      <div className="totals">
        <AggregatedItem icon={() => <AiOutlineScan className="icon" />} title="Total Verifications" value={aggregatedData.total_verify_count} customClass="mb-2"/>
        <AggregatedItem icon={() => <BsXOctagon className="icon" />} title="Failed" value={aggregatedData.fail_verify_count} customClass="mb-2"/>
        <AggregatedItem icon={() => <FiCheckCircle className="icon" />} title="Succeeded" value={aggregatedData.pass_verify_count} />
      </div>

      <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-center">
        <div className="doughnut d-flex align-items-center justify-content-center">
          <div className="whiteBg d-flex align-items-center flex-column justify-content-center">
            <p className="mb-0 font700 px14">TOTAL</p>
            <FlipNumbers
              play
              color="#4f4f4f"
              width={10}
              height={17}
              numbers={`${aggregatedData.total_verify_count}`}
              duration={1}
            />
          </div>
          <Doughnut data={data}
            options={options}
          />
        </div>

        <div className="labels">
          <p className="title font400 px14">Succeeded VS Failed</p>
          <div className="d-flex flex-row white align-items-center mb-1">
            <div className="counterfeit"></div>
            <p className="mb-0 font400 px16">Failed</p>
          </div>
          <div className="d-flex flex-row white align-items-center">
            <div className="genuine"></div>
            <p className="mb-0 font400 px16">Succeeded</p>
          </div>
        </div>

      </div>
    </div>
  )
}
const mapStateToProps = ({ dashboardReducer: { aggregatedData } }) => ({
  aggregatedData
})

export default connect(mapStateToProps, {})(AggregatedData)