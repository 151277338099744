import React from "react";
import { connect } from 'react-redux'
import moment from "moment";
import { MdCheckCircle } from 'react-icons/md'
import { BsFillExclamationOctagonFill } from 'react-icons/bs'
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

const LogsTable = (props) => {

  const { logsData, newLogs } = props;
  var heading = ['Product', 'Brand', 'Time', 'Result'];
  const mapStatus = {
    "failidentify": "Not Found",
    "passidentify": "Found",
    "failverify": "Failed",
    "passverify": "Succeeded"
  }

  const TableRow = (props) => {
    const { row, index } = props;
    if (index < newLogs.length) {
      return (
        <Fade top={true} duration={600}>
          <tr className={`${index % 2 === 0 && 'rowBg'}`} key={index}>
            <td className="white60 px14 font400 mw-25 d-inline-block text-truncate">{row.productName ? row.productName : "N/A"}</td>
            <td className="white60 px14 font400">{row.brandName ? row.brandName : "N/A"}</td>
            <td className="white60 px14 font400">{moment(row.scanTime).format("LT")}</td>
            <td className="white60 px14 font400">{mapStatus[row.status + row.type]}</td>
            <td className="font400">
              <div className="iconBg"></div>
              {row.status === 'pass' ? <MdCheckCircle color="#0072BC" className="icon" size={20} /> : <BsFillExclamationOctagonFill color="#FEBC11" size={18} className="icon" />}
            </td>
          </tr>
        </Fade>
      )
    } else if (newLogs.length > 0) {
      return (
        <Slide top={true} duration={600}>
          <tr className={`${index % 2 === 0 && 'rowBg'}`} key={index}>
            <td className="white60 px14 font400  mw-25 d-inline-block text-truncate">{row.productName ? row.productName : "N/A"}</td>
            <td className="white60 px14 font400">{row.brandName ? row.brandName : "N/A"}</td>
            <td className="white60 px14 font400">{moment(row.scanTime).format("LT")}</td>
            <td className="white60 px14 font400">{mapStatus[row.status + row.type]}</td>
            <td className="font400">
              <div className="iconBg"></div>
              {row.status === 'pass' ? <MdCheckCircle color="#0072BC" className="icon" size={20} /> : <BsFillExclamationOctagonFill color="#FEBC11" size={18} className="icon" />}
            </td>
          </tr>
        </Slide>
      )
    } else {
      return (
        <tr className={`${index % 2 === 0 && 'rowBg'}`} key={index}>
          <td className="white60 px14 font400 mw-25 d-inline-block text-truncate">{row.productName ? row.productName : "N/A"}</td>
          <td className="white60 px14 font400">{row.brandName ? row.brandName : "N/A"}</td>
          <td className="white60 px14 font400">{moment(row.scanTime).format("LT")}</td>
          <td className="white60 px14 font400">{mapStatus[row.status + row.type]}</td>
          <td className="font400">
            <div className="iconBg"></div>
            {row.status === 'pass' ? <MdCheckCircle color="#0072BC" className="icon" size={20} /> : <BsFillExclamationOctagonFill color="#FEBC11" size={18} className="icon" />}
          </td>
        </tr>
      )
    }
  }

  return (
    <div className="logs d-flex flex-column py-3 flex-grow-1 overflow-hidden mb-3">
      <p className="title px14 font400 mb-2 px-3">Transactions</p>
      <table>
        <thead>
          <tr>
            {heading.map((head, idx) => <th className={`mw-25 white px16 font400`} key={idx}>{head}</th>)}
          </tr>
        </thead>
        <tbody>
          {logsData && logsData.map((row, idx) => <TableRow row={row} index={idx} key={idx} />)}
        </tbody>
      </table>
    </div>
  )

}
const mapStateToProps = ({ dashboardReducer: { logsData, newLogs } }) => ({
  logsData, newLogs
})

export default connect(mapStateToProps, {})(LogsTable);